import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import icon from '../assets/iconrm.png';
import icon2 from '../assets/egg (2).png';
import stickyImg from '../assets/Asset 3@3x.png';

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});
function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 15;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});


class Team extends Component {

    async componentDidMount() {

    }

    render() {
        return (
            <div class="boxWrap2Rm">

                <div class="conT-2">NFT Collection Roadmap</div>

                <div class="glassy">
                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>1. Magical Genesis</h2>
                            <h2><span class="smallL">(Month 1-2)</span></h2>
                            
                            <p><img class="rmNote" src={icon2} /><span className='special'>Conceptualization and Storyboarding:</span> Lola and Lemon's magical journey begins with Lola crafting the foundational narrative and storyboarding the whimsical adventures.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Character Design and Initial Sketches:</span> Lola collaborates with artists to bring Lemon and his fantastical world to life, creating the first batch of concept sketches.</p>
 
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />

                            </div>
                        </li>
                        <li>
                            <h2>2. Vibrant Colors Unleashed</h2>
                            <h2><span class="smallL">(Month 3-4)</span></h2>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Color Palette Selection:</span> Lola, inspired by the enchanting colors of the Dreamweaver's fruits, works closely with artists to choose vibrant and lively color palettes for each NFT.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Artwork Development:</span> Artists breathe life into the sketches, transforming them into vivid and animated masterpieces.</p>
 
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>
                        <li>
                            <h2 class="screen">3. Dreamweaver's Interactive Playground :</h2>
                            <h2 class="mobH2">3. Dreamweaver's Interactive <br/>Playground :</h2>
                            <h2><span class="smallL">(Month 5-6)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Smart Contract Development:</span> The Dreamweaver's magical essence is translated into a secure and child-friendly smart contract, ensuring the NFT collection is accessible and enjoyable for kids and families.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Platform Development:</span> Create an interactive platform with playful features, allowing users to explore, trade, and engage with Lola's DinoDreams.</p>

                             <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2>4. Grand Unveiling Gala :</h2>
                            <h2><span class="smallL">(Month 7)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Virtual Launch Party:</span> Lola hosts a virtual gala, inviting the community to celebrate the grand unveiling of the NFT collection and sharing the enchanting backstory of Lemon.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Limited Edition Drops:</span> Release the first set of NFTs in limited editions, each telling a unique chapter of Lola and Lemon's magical journey.</p>
                             <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2 class="screen">5. Community Quests and Activities :</h2>
                            <h2 class="mobH2">5. Community Quests and <br/>Activities :</h2>
                            <h2><span class="smallL">(Month 8-9)</span></h2>

                                  <p><img class="rmNote" src={icon2} /><span className='special'>Interactive Storytelling:</span> Launch interactive quests and activities for the community, allowing them to contribute to Lola and Lemon's ongoing adventures.</p>
                                <p><img class="rmNote" src={icon2} /><span className='special'>Exclusive Rewards:</span> Introduce exclusive rewards for active community members, fostering a sense of belonging in the Dreamweaver's magical realm.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2>6. Seasonal Drops and Upgrades :</h2>
                            <h2><span class="smallL">(Month 10-12)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Seasonal Themes:</span> Introduce themed NFT drops tied to seasons, festivals, or special events, keeping the collection fresh and engaging.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Upgrades and Enhancements:</span> Implement platform upgrades based on community feedback, ensuring a continuously improved and enchanting user experience.</p>
                            
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2>7. Dreams for a Cause :</h2>
                            <h2><span class="smallL">(Ongoing)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Charitable Contributions:</span> Allocate a percentage of NFT sales to the chosen charities, with regular updates and acknowledgments from Lola herself.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Charity-Driven NFTs:</span> Introduce special NFTs specifically designed to support charitable causes, creating a direct link between the magical world of DinoDreams and real-world impact.</p>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2 class="screen">8. Community Expansion and Collaboration :</h2>
                            <h2 class="mobH2">8. Community Expansion and <br/>Collaboration :</h2>
                            <h2><span class="smallL">(Ongoing)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Collaborative Events:</span> Partner with other NFT projects, artists, and child-friendly communities to expand the Dreamweaver's realm.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Interactive Workshops:</span> Host creative workshops and events, encouraging young artists to contribute to the magical world of Lola's DinoDreams.</p>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2>9. Legendary Anniversary Drop :</h2>
                            <h2><span class="smallL">(Year 1)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Anniversary Celebration:</span> Commemorate the first anniversary with a legendary drop, featuring exclusive NFTs and surprises for the community.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Community Appreciation:</span> Express gratitude to the Dreamweaver's growing community, acknowledging their role in making Lola's DinoDreams a magical success.</p>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2>10. Everlasting Magic :</h2>
                            <h2><span class="smallL">(Ongoing)</span></h2>

                            <p><img class="rmNote" src={icon2} /><span className='special'>Continuous Storytelling:</span> Lola and Lemon's adventures continue to unfold through periodic NFT drops, ensuring the magical tale never loses its charm.</p>
                            <p><img class="rmNote" src={icon2} /><span className='special'>Legacy Features:</span> Introduce features that allow NFT owners to pass on the magic to future generations, fostering a sense of continuity and everlasting enchantment.</p>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                    </ul>
                </div>
                </div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>
				<div class="firefly"></div>

            <img class="stickyImg" src={stickyImg}/>
            
            </div>
        )
    }
}

export default Team;