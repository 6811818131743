import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import egg from '../assets/egg.jpg'
import dragon from '../assets/logo.png'
import girl from '../assets/girl.jpg';
import eggBurst from '../assets/eggburst.jpg';
import chamber from '../assets/chamber.jpg';
import d1 from '../assets/d1.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Token extends Component {

    render() {
        return (

            <div class="boxWrap2Story3">
                <div class="conT">OUR STORY</div>

                <div class="aboutToken" data-aos="fade-up" data-aos-duration="1000">

                    <div class="aboutTokenCon">
                        <div class="conT2">Chapter 1</div>
                        <div className='hl'>The Birth of Lemon</div>
                        <img class="token" src={egg}></img>

                        <p>In the heart of the Enchanted Forest, nestled within the branches of the Whispering Willow, a magical tree known as the Dreamweaver bore witness to a wondrous event. Within the luminescent fruits that hung from its branches, a mysterious dinosaur egg began to grow. Unbeknownst to the forest creatures, this egg was destined to hatch into the charming dinosaur, Lemon.</p>
                    </div>

                </div>

                <p class="top"></p>

                <div class="aboutToken" data-aos="fade-up" data-aos-duration="1000">

                    <div class="aboutTokenCon">
                        <div class="conT2">Chapter 2</div>
                        <div className='hl'>Growing Up in the Fruitful Wonders</div>
                        <img class="token" src={girl} />

                        <p>As days turned to nights, the magical fruits cradled the growing Lemon, infusing him with the essence of the enchanted orchard. Lola, a spirited 10-year-old, discovered the growing dinosaur egg during one of her magical adventures in the Enchanted Forest. Drawn by a bond only children and magical creatures understand, Lola and Lemon formed an unbreakable friendship as he continued to grow within the magical fruits.</p>
                    </div>

                </div>

                <p class="top"></p>

                <div class="aboutToken" data-aos="fade-up" data-aos-duration="1000">

                    <div class="aboutTokenCon">
                        <div class="conT2">Chapter 3</div>
                        <div className='hl'>The First Burst of Color</div>
                        <img class="token" src={eggBurst} />

                        <p>One enchanting evening, as the moon cast its silver glow over the Dreamweaver's branches, Lemon's egg burst open in a burst of dazzling colors. The magical hues painted the sky as Lola and Lemon shared a magical moment, surrounded by the shimmering fruits that had nurtured him.</p>
                    </div>

                </div>

                <p class="top"></p>

                <div class="aboutToken" data-aos="fade-up" data-aos-duration="1000">

                    <div class="aboutTokenCon">
                        <div class="conT2">Chapter 4</div>
                        <div className='hl'>The Birth of LemonWhimsical Adventures Begin</div>
                        <img class="token" src={dragon} />

                        <p>With each passing day, Lola and Lemon set off on whimsical adventures through the Enchanted Forest. They discovered hidden realms, met fantastical creatures, and encountered challenges that strengthened their bond. Lola, inspired by their adventures, started sketching their escapades, giving life to the vibrant world of DinoDreams.</p>
                    </div>

                </div>

                <p class="top"></p>

                <div class="aboutToken" data-aos="fade-up" data-aos-duration="1000">

                    <div class="aboutTokenCon">
                        <div class="conT2">Chapter 5</div>
                        <div className='hl'>The Dreamweaver's Gift</div>
                        <img class="token" src={chamber} />

                        <p>Deep within the Dreamweaver's roots, Lola and Lemon discovered a secret chamber filled with sparkling stardust. The Dreamweaver, in gratitude for Lola's friendship with Lemon, gifted them the ability to turn Lola's sketches into animated NFTs, allowing others to share in the magic of their adventures.</p>
                    </div>

                </div>

                <p class="top"></p>

                <div class="aboutToken" data-aos="fade-up" data-aos-duration="1000">

                    <div class="aboutTokenCon">
                        <div class="conT2">Chapter 6</div>
                        <div className='hl'>Unveiling Lola's DinoDreams</div>
                        <img class="d1" src={d1} />

                        <p>As the collection of NFTs took shape, Lola and Lemon decided to share their magical world with the world. The Dreamweaver's magic infused each NFT with a touch of enchantment, making them not just digital art but windows into a world where dreams and reality coexist.</p>
                    </div>

                </div>

            </div>
        )
    }
}

export default Token;

