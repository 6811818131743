import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import twitter from '../assets/twitter-.png';
import discrd from '../assets/discord.png';
import opensea from '../assets/opensea.png';
import logo from '../assets/logo.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const os = () => {
    window.open("https://opensea.io/collection/lola-s-dinodreams-a-magical-journey-with-lemon");
}

const tweet = () => {
    window.open("https://twitter.com/Lolasdinodream");
}

const discord = () => {
    window.open("#");
}

class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
             
                <div class="footer">
                    <p class="punkSubH">Lola's DinoDreams NFT Collection is not just a journey; it's a magical odyssey that evolves with the dreams and contributions of its vibrant community, creating a legacy that transcends the digital realm.</p>

                    <img class="logoF" src={logo} />

                    <div class="icons">
                        <img onClick={tweet} src={twitter} />
                        <img onClick={discord} src={discrd} />
                        <img onClick={os} src={opensea} />
                    </div>

                    <div class="copyright">Copyright © 2023 Lola's DinoDreams. All Rights Reserved</div>


                </div>



            </div>
        )
    }
}

export default Footer;

